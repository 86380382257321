<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">COMPANIES</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataCompanies"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Entity">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.entity"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="TRN">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.tax"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Address">
            <template #default="scope">
              <el-input size="small"
              type="textarea"
                style="text-align:center"
                v-model="scope.row.address"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Phone">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.phone"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Category">
            <template #default="scope">
              <el-select v-model="scope.row.category" multiple placeholder="Select">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= companies.length">
                <el-button
                 @click.native.prevent="addCompany(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add Company
        </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Company',

  data () {
    return {
      dataCompanies: [],
      options: [{
        value: 'Tickets',
        label: 'Tickets'
      }, {
        value: 'Events',
        label: 'Events'
      }, {
        value: 'Subscriptions',
        label: 'Subscriptions'
      }, {
        value: 'Lessons',
        label: 'Lessons'
      }, {
        value: 'Books',
        label: 'Books'
      }]
    }
  },

  computed: {
    ...mapState({
      companies: state => state.company.companies
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeCompany', row._id)
        .then(() => {
          this.dataCompanies.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Company removed successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      this.$store.dispatch('editCompany', {
        id: row.uid,
        content: row
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Company edited successfully',
            type: 'success'
          })
        })
    },
    addCompany (payload) {
      this.$store.dispatch('addCompany', payload)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Company created successfully',
            type: 'success'
          })
        })
    },
    addRow () {
      let newRow = {
        name: '',
        tax: '',
        category: [],
        entity: '',
        address: '',
        phone: ''
      }
      this.dataCompanies = [...this.dataCompanies, newRow]
    }
  },
  mounted () {
    this.dataCompanies = this.companies.map((x) => Object.assign({}, x))
  }
}
</script>
